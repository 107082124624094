<template>
  <div id="shippinginvoice-list">
    <v-card>
      <v-card-title>日伯伝票</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-autocomplete
            v-model="product_code"
            :items="ProductCodes"
            placeholder="商品コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="delv_pud"
                placeholder="伝票作成日"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="delv_pud"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="delv_due_date"
                placeholder="出荷期限日"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="delv_due_date"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-autocomplete
            v-model="delv_status"
            :items="statuss"
            placeholder="状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'shippinginvoice-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
            @click="exportPDF"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiClipboardArrowDownOutline }}
            </v-icon>
            <span>DownLoad</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="deliveryslipsList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.ord_id`]="{ item }">
          <InvoiceDialog
            :id="item.ord_id"
            @refresh="refreshList"
          >
          </InvoiceDialog>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.addr_sei }} {{ item.addr_mei }}</a>
            </template>
            <span>{{ item.addr_sei }}   {{ item.addr_mei }}</span><br>
            <span>{{ item.addr_sei_kana }}   {{ item.addr_mei_kana }}</span><br>
            <span>{{ item.addr_zip }}</span><br>
            <span>{{ item.addr_addr1 }}{{ item.addr_addr2 }}</span><br>
            <span>{{ item.addr_telno }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <label
                v-bind="attrs"
                v-on="on"
              >
                {{ item.product_name }}
              </label>
            </template>
            <span>{{ item.product_code }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.tracking_no`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p
                class="class-with-title"
                style="margin: 0; width:8rem; overflow: hidden; text-overflow:ellipsis;white-space: nowrap;"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.tracking_no }}
              </p>
            </template>
            <span>{{ item.tracking_no }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-row>
            <v-col
              style="padding-right: 10px"
              cols="4"
            >
              <v-icon
                class="mr-2"
                small
                @click.stop="exportDeliveryslip(item)"
              >
                {{ icons.mdiEye }}
              </v-icon>
            </v-col>
            <v-col
              style="padding-right: 10px"
              cols="4"
            >
              <DeliNO
                :id="item.delv_id"
              ></DeliNO>
            </v-col>
          </v-row> -->
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click.stop="exportDeliveryslip(item)"
              >
                {{ icons.mdiEye }}
              </v-icon>
            </div>
            <DeliNO
              :id="item.delv_id"
              class="py-2 pl-1"
            ></DeliNO>
          </div>
        </template>
        <template v-if="totalSumShow" v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="5"
            >
              <label>数量合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ delvTotalSum }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiPlus, mdiClipboardArrowDownOutline, mdiEye,
} from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'
// import Detail from './ShippingInvoiceDetail.vue'
import InvoiceDialog from '@/views/bimi/shipping/Invoice/InvoiceDialog.vue'
import DeliNO from '@/views/bimi/shipping/Invoice/DeliNoDialog.vue'
import printHtml from '@/utils/print'
/* eslint-disable */

export default {
  components: {
    DeliNO,
    InvoiceDialog,
  },
  data: () => ({
    documentTitle: '',
    addr_sei_kana: '',
    addr_mei_kana: '',
    delv_status: '',
    delv_pud: '',
    dateStartMenu: '',
    delv_due_date: '',
    dateEndMenu: '',
    search: '',
    statuss: [],
    icons: {
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiEye,
    },
    headers: [
      {
        text: 'ID',
        value: 'delv_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '伝票番号',
        value: 'delv_slip_no',
        align: 'left',
        width: '12%',
        fixed: true,
      },
      {
        text: '注文ID',
        value: 'ord_id',
        width: '8%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '送付先',
        value: 'name',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: 'セイ',
        value: 'addr_sei',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'addr_mei',
        align: ' d-none',
      },
      {
        text: '郵便番号',
        value: 'addr_zip',
        align: ' d-none',
      },
      {
        text: '住所1',
        value: 'addr_addr1',
        align: ' d-none',
      },
      {
        text: '住所2',
        value: 'addr_addr2',
        align: ' d-none',
      },
      {
        text: '電話番号',
        value: 'addr_telno',
        align: ' d-none',
      },
      {
        text: '商品コード',
        value: 'product_code',
        align: ' d-none',
      },
      {
        text: '商品名',
        value: 'product_name',
        width: '25%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      // {
      //   text: '追跡番号',
      //   value: 'tracking_no',
      //   width: '10%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: false,
      // },
      {
        text: '数量',
        value: 'delv_qty',
        width: '8%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '伝票作成日',
        value: 'delv_pud',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '出荷期限日',
        value: 'delv_due_date',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '7%',
        align: 'right',
        fixed: true,
      },
    ],
    product_code: '',
    totalSumShow: false,
  }),
  computed: {
    ...mapState('productStore', ['ProductCodes']),
    ...mapState('shippingStore', ['deliveryslipsList', 'selectedDeliveryslip','deliverieyStatus']),
    delvTotalSum() {
      let totalSum = 0
      this.deliveryslipsList.forEach(obm => {
        if (obm.delv_qty) {
          totalSum += obm.delv_qty
        }
      })

      return totalSum.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
  },
  watch: {
    product_code(newValue) {
      this.changeRouteQuery('product_code', newValue)
      this.refreshList()
      if (newValue === '' || newValue === null) {
        this.totalSumShow = false
      } else {
        this.totalSumShow = true
      }
    },
    delv_status(newValue) {
      this.changeRouteQuery('delv_status', newValue)
      this.refreshList()
    },
    delv_pud(newValue) {
      this.changeRouteQuery('delv_pud', newValue)
      this.refreshList()
    },
    delv_due_date(newValue) {
      this.changeRouteQuery('delv_due_date', newValue)
      this.refreshList()
    },
  },
  mounted() {
    const that = this
    window.onafterprint = function(){
      document.title = that.documentTitle ?? 'BimiStock'
    }
  },
  created() {
    this.changeRouteQuery()
    this.loadQuery()
    this.refreshList()
  },
  destroyed() {
    this.clearProductCodes()
    this.clearDeliveryslipsList()
    this.clearSelectedDeliveryslip()
    this.clearDeliveryStatusList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('productStore', ['loadProductList']),
    ...mapActions('shippingStore', ['loadDeliveryslipsList', 'loadDeliveryslip', 'exportDeliverySlips','loadDeliveryStatusList']),
    ...mapMutations('productStore', ['clearProductCodes']),
    ...mapMutations('shippingStore', ['clearDeliveryslipsList', 'clearSelectedDeliveryslip', 'clearDeliveryStatusList']),
    changeSeiKana() {
      this.changeRouteQuery('addr_sei_kana', this.addr_sei_kana)
      this.refreshList()
    },
    changeMeiKana() {
      this.changeRouteQuery('addr_mei_kana', this.addr_mei_kana)
      this.refreshList()
    },
    changeTelephoneNum() {
      this.changeRouteQuery('addr_telno', this.addr_telno)
      this.refreshList()
    },
    exportDeliveryslip(item) {
      this.setOverlayStatus(true)
      this.loadDeliveryslip(item.delv_id).then(() => {
        this.documentTitle = document.title
        const currentTime = new Date(Date.now())
        document.title = `${item.delv_slip_no}-${currentTime.getFullYear()}${currentTime.getMonth()}${currentTime.getDay()}${currentTime.getHours()}${currentTime.getMinutes()}${currentTime.getSeconds()}`
        if (this.selectedDeliveryslip.html) {
          const printData = this.selectedDeliveryslip.html
          printHtml(printData)
        } else {
          console.log('selectedDeliveryslip html not exist')
        }

        this.setOverlayStatus(false)
      }).catch(error => {
        console.log('error', error)
        this.setOverlayStatus(false)
      })
    },

    exportPDF() {
      this.setOverlayStatus(true)
      this.exportDeliverySlips(this.$route.query).then(() => {
        this.setOverlayStatus(false)
      }).catch(error => {
        this.setOverlayStatus(false)
        console.log('exportPDF error', error)
      })
    },

    refreshList() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadProductList(this.$route.query),
        this.loadDeliveryStatusList(),
        this.loadDeliveryslipsList(this.$route.query),
      ]).then(() => {
        this.getStatuItem()
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.addr_sei_kana = this.$route.query.addr_sei_kana ?? ''
      this.addr_mei_kana = this.$route.query.addr_mei_kana ?? ''
      this.addr_telno = this.$route.query.addr_telno ?? ''
      this.product_code = this.$route.query.product_code ?? ''
      this.delv_pud = this.$route.query.delv_pud ?? ''
      this.delv_due_date = this.$route.query.delv_due_date ?? ''
      this.delv_status = this.$route.query.delv_status ?? ''
    },

    getStatuItem() {
      this.statuss = this.deliverieyStatus.map(x => {
        const obj = {
        }
        obj.text = `${x.ds_code}: ${x.ds_desc}`
        obj.value = x.ds_code

        return obj
      })
    },
  },
}
</script>

<style>
.table-filed {
  white-space: nowrap;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
